"use client";

import Changelog from "@/CHANGELOG.md";
import Link from "next/link";

export default function Home() {
	return (
		<>
			<h1 className="title is-1" role="heading">
				🍴 Our Cookbook
			</h1>

			<div className="content">
				Check out
				<ul>
					<li>
						our <Link href="/recipes/">🍳 Recipes</Link>
					</li>
					<li>
						our <Link href="/menus/">📜 Menus</Link>
					</li>
				</ul>
				<p>Maybe we&apos;ll add a feed here, someday!</p>
				<Changelog />
			</div>
		</>
	);
}
